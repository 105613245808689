<template>
  <div>

    <ErrorPopup
      :error="error_popup_message"
    />

    <b-form-group v-if="settings.is_faith" :label="$t('MEMBER.LANG')" class="col-4">
      <b-form-select
        v-model="lang"
        :options="[
          { value: 'sv', text: $t('LANGUAGE.SWEDISH') },
          { value: 'ar', text: $t('LANGUAGE.ARABIC') },
          { value: 'bs', text: $t('LANGUAGE.BOSNIAN') }
        ]"
      >

      </b-form-select>

    </b-form-group>


    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="registration-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="member-registrations-history-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="headers"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="registration-table"
      @filtered="on_filtered"
      @sort-changed="on_sort_changed"
    >


      <template #cell(actions)="row">

        <div class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

          <a
            v-if="settings.is_faith"
            href="#"
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="print_member_application(row.item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/print-solid.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>

        </div>
      </template>

      <template #cell(selected)="row">
        <v-checkbox
          :ripple="false"
          v-model="row.item.selected"
          class="my-0 py-0"
          color="#3699FF"
          hide-details
        ></v-checkbox>
      </template>

    </b-table>

    <div v-if="total_rows === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {
  name: 'MemberRegistrationsHistoryTable',
  mixins: [ toasts ],
  props: ['show_create'],
  emits: ['select_member_clicked'],
  components: {
    ErrorPopup
  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId','settings']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    get_options() {

      const options = {};

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      options.statuses = ['MEMBER'];

      return options;
    },

    select_member_clicked(member_id) {
      this.$emit('select_member_clicked', member_id);
    },

    refresh() {
      this.$refs['registration-table'].refresh();
    },

    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['memberTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/member/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },

    on_sort_changed(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/member/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.total_rows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_GET_NUM_ORDERS'));
        });
    },

    on_filtered() {},

    async print_member_application(member_id) {
      try {
        const res = await axios.post(`/samfund/joined_member/${member_id}`, { lang: this.lang });

        if (res.status === 409) {
          if (res.data.reason.indexOf('skv_period') >= 0) {
            this.error_popup_message = 'ERR_MEMBER_MISSING_SKV_PERIOD';

            this.$nextTick(()=>{ this.error_popup_message = null; });
            return;
          }
        }
        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_DOWNLOAD'));
          return;
        }

        downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

      } catch (error) {
        console.error(error);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_DOWNLOAD'));
      }
    },

  },
  data() {
    return {

      lang: 'sv',

      orderBy: {
        created_at: 'desc'
      },

      error_popup_message: null,

      selected_member_id: null,

      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [

        {
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID_SHORT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'cname',
          label: this.$t('MEMBER.NAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            if (item.cname) {
              return item.cname;
            }

            return (item.firstname || '').substring(0,1) + '. ' + item.lastname;
          }
        },

        {
          key: 'created_at',
          label: this.$t('MEMBER.CREATED_AT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'accepted_at',
          label: this.$t('PAGES.MEMBER_JOBS.ACCEPTED_AT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-12 align-middle',
        },

      ],

    };
  }
};
</script>
